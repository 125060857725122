"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceWildcardsInTextWithData = void 0;
const SEARCH_STRINGS = {
    vorname: [
        "#VORNAME",
        "#FIRSTNAME",
        "#FIRST_NAME",
        "#FORENAME",
        "#GIVEN_NAME",
        "#GIVENNAME",
    ],
    nachname: [
        "#FAMILYNAME",
        "#FAMILIENNAME",
        "#NACHNAME",
        "#LASTNAME",
        "#LAST_NAME",
    ],
    fullname: ["#NAME", "#FULLNAME"],
    land: ["#LAND", "#COUNTRY"],
    city: ["#CITY", "#STADT"],
    plz: ["#PLZ", "#POSTALCODE", "#ZIP"],
    salutation: ["#SALUTATION", "#ANREDE", "#BEGRÜSSUNG", "#BEGRÜSSUNG"],
    hausnummer: [
        "#HAUSNUMMER",
        "#HOUSENUMBER",
        "#HOUSE_NUMBER",
        "#STREETNUMBER",
        "#STREET_NUMBER",
    ],
    strasse2: ["#STRASSE2", "#STRASSE_2", "#STREET2", "#STREET_2"],
    strasse: ["#STRASSE", "#STRASSE", "#STREET"],
    email: ["#EMAIL", "#E-MAIL", "#E-Mail"],
    title: ["#TITLE", "#TITEL"],
};
const replaceWildcards = (input, replacementData) => {
    if (typeof input !== "string")
        return input;
    let inputCopy = input;
    for (const reaplacementKey in SEARCH_STRINGS) {
        for (const replacementSearchString of SEARCH_STRINGS[reaplacementKey]) {
            // Normal (UPPERCASE)
            inputCopy = inputCopy.replace(new RegExp(replacementSearchString, "g"), replacementData[reaplacementKey] ?? "");
            // First is uppercase, rest lowercase
            inputCopy = inputCopy.replace(new RegExp(replacementSearchString.substring(0, 2).toUpperCase() +
                replacementSearchString.substring(2).toLowerCase(), "g"), replacementData[reaplacementKey] ?? "");
            // All lowercase
            inputCopy = inputCopy.replace(new RegExp(replacementSearchString.toLowerCase(), "g"), replacementData[reaplacementKey] ?? "");
            // Whitspace instead of _ (uppercase)
            inputCopy = inputCopy.replace(new RegExp(replacementSearchString.replace(/_/g, " "), "g"), replacementData[reaplacementKey] ?? "");
            // Whitspace instead of _ (lowercase)
            inputCopy = inputCopy.replace(new RegExp(replacementSearchString.replace(/_/g, " ").toLowerCase(), "g"), replacementData[reaplacementKey] ?? "");
            // Whitspace instead of _ (first upper, rest lower)
            inputCopy = inputCopy.replace(new RegExp(replacementSearchString.substring(0, 2).toUpperCase() +
                replacementSearchString
                    .substring(2)
                    .toLowerCase()
                    .replace(/_/g, " "), "g"), replacementData[reaplacementKey] ?? "");
            inputCopy = inputCopy.replace(new RegExp(replacementSearchString, "g"), replacementData[reaplacementKey] ?? "");
        }
    }
    return inputCopy;
};
const replaceWildcardsInTextWithData = (inputs, schemas, replacementData) => {
    const inputKeysOfTypeText = Object.entries(schemas?.[0] || {})
        ?.filter((entrie) => entrie?.[1]?.type === "text")
        .map(([key]) => key) || [];
    const inputsCopy = { ...inputs };
    for (const inputKey of inputKeysOfTypeText) {
        if (inputs[inputKey]) {
            inputsCopy[inputKey] = replaceWildcards(inputsCopy[inputKey], replacementData);
        }
    }
    return inputsCopy;
};
exports.replaceWildcardsInTextWithData = replaceWildcardsInTextWithData;
