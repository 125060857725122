"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformAddressesToParsedRows = exports.bulkOrderArrayToCSV = void 0;
const config_1 = require("./config");
const json_2_csv_1 = require("json-2-csv");
const dataHandling_1 = require("./dataHandling");
/**
 * Function which transforms the Array of objects to have a matched Object with the Strapi Content Type "Address"
 */
const transformAddressesToParsedRows = (addresses, prodObj) => {
    const parsed = [];
    addresses.forEach((address) => {
        parsed.push({
            [(0, config_1.lookupHeadersConfig)({ id: "nummer" }).name]: address?.nummer,
            [(0, config_1.lookupHeadersConfig)({ id: "vorname" }).name]: address?.vorname,
            [(0, config_1.lookupHeadersConfig)({ id: "nachname" }).name]: address?.nachname,
            [(0, config_1.lookupHeadersConfig)({ id: "title" }).name]: address?.title,
            [(0, config_1.lookupHeadersConfig)({ id: "companyName" }).name]: address?.companyName,
            [(0, config_1.lookupHeadersConfig)({ id: "land" }).name]: address?.land || "Deutschland",
            [(0, config_1.lookupHeadersConfig)({ id: "plz" }).name]: address?.plz,
            [(0, config_1.lookupHeadersConfig)({ id: "city" }).name]: address?.city,
            [(0, config_1.lookupHeadersConfig)({ id: "strasse" }).name]: address?.strasse,
            [(0, config_1.lookupHeadersConfig)({ id: "hausnummer" }).name]: address?.hausnummer,
            [(0, config_1.lookupHeadersConfig)({ id: "strasse2" }).name]: address?.strasse2,
            [(0, config_1.lookupHeadersConfig)({ id: "email" }).name]: address?.email,
            [(0, config_1.lookupHeadersConfig)({ id: "salutation" }).name]: address?.salutation,
            [(0, config_1.lookupHeadersConfig)({ id: "salutation2" }).name]: "",
            [(0, config_1.lookupHeadersConfig)({ id: "produkt1" }).name]: prodObj["Produkt 1 (individuell)"],
            [(0, config_1.lookupHeadersConfig)({ id: "quantityP1" }).name]: prodObj["Quantity P1"],
            [(0, config_1.lookupHeadersConfig)({ id: "produkt2" }).name]: prodObj["Produkt 2"],
            [(0, config_1.lookupHeadersConfig)({ id: "quantityP2" }).name]: prodObj["Quantity P2"],
            [(0, config_1.lookupHeadersConfig)({ id: "produkt3" }).name]: prodObj["Produkt 3"],
            [(0, config_1.lookupHeadersConfig)({ id: "quantityP3" }).name]: prodObj["Quantity P2"],
            [(0, config_1.lookupHeadersConfig)({ id: "trackingLink" }).name]: "",
            [(0, config_1.lookupHeadersConfig)({ id: "trackingNumber" }).name]: "",
        });
    });
    return parsed;
};
exports.transformAddressesToParsedRows = transformAddressesToParsedRows;
/**
 * Function which transforms the Array of objects to have a matched Object with the Strapi Content Type "Address"
 */
const transformLineItemsToParsed = (lineItems) => {
    const resultObj = {};
    const cpInstanceLineItems = lineItems.filter((lineItem) => lineItem.cp_instance !== null);
    if (cpInstanceLineItems.length >= 2)
        throw new Error("Pro Addressblock ist nur ein individuelles Produkt im CSV erlaubt.");
    if (cpInstanceLineItems.length === 1) {
        const cpInstance = cpInstanceLineItems?.[0].cp_instance;
        resultObj["Produkt 1 (individuell)"] = cpInstance?.name,
            resultObj["Quantity P1"] = cpInstanceLineItems?.[0]?.quantity;
    }
    const productVariantLineItems = lineItems.filter((lineItem) => lineItem.product_variant !== null);
    const indexOffset = cpInstanceLineItems.length === 1 ? 1 : 2;
    return productVariantLineItems.reduce((akku, lineItem, index) => {
        if (lineItem?.product_variant !== null) {
            // ToREST is not recursiv, therefore every repeatable component or relation has to be passed in again
            const productVariant = lineItem?.product_variant;
            akku[`Produkt ${index + indexOffset}`] = productVariant?.sku,
                akku[`Quantity P${index + indexOffset}`] = lineItem?.quantity;
        }
        return akku;
    }, resultObj);
};
const bulkOrderArrayToCSV = async (bulkOrderId, returnBlob) => {
    const bulkAddressData = await (0, dataHandling_1.fetchAddressBlocksForOrder)(bulkOrderId);
    const options = {
        delimiter: {
            field: ';',
            eol: '\n'
        },
        emptyFieldValue: "",
        trimFieldValues: true,
        prependHeader: true,
        keys: config_1.csvHeadersForDownload
    };
    let converted = "";
    const emptyRow = config_1.csvHeadersForDownload.reduce((akku, key) => ({ ...akku, [key]: "" }), {});
    if (bulkAddressData.length === 0) {
        converted = await (0, json_2_csv_1.json2csvAsync)([emptyRow], options);
    }
    else {
        let rows = [];
        bulkAddressData?.map((addressBlock) => {
            //Transform the lineItems back to the CSV column structure
            const reparsedProducts = transformLineItemsToParsed(addressBlock?.lineItems);
            //Transform the Addresses for the right CSV Column structure together with the reparsedProducts
            const parsedAddresses = transformAddressesToParsedRows(addressBlock?.addresses, reparsedProducts);
            rows.push(parsedAddresses);
        });
        //Flat the nested Arrays of the different AddressBlocks
        rows = rows.flat(2);
        converted = await (0, json_2_csv_1.json2csvAsync)(rows, options);
    }
    //Create a file blob and a objectURL and return the url
    const blob = new Blob([converted], { type: 'text/csv;charset=utf-8;' });
    if (returnBlob) {
        return blob;
    }
    const url = URL.createObjectURL(blob);
    if (!url) {
        throw new Error("bulkOrderCreateCSV was not able to create a createObjectURL url for the file blob you tried");
    }
    return url;
};
exports.bulkOrderArrayToCSV = bulkOrderArrayToCSV;
