"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseProductsOfAddress = exports.parseProductsToConfiguration = exports.csvFileToArray = exports.transformParsedToAddresses = void 0;
const config_1 = require("./config");
const json_2_csv_1 = require("json-2-csv");
/**
 * Function which takes a ";"-seperated string (CSV) and parses it.
 * Additionally:
 * -> the function checks for missing Columnes/Headers via the "csvHeadersConfig"
 * -> the function checks for missing values for "required" fields via "csvHeadersConfig"
 * @returns array (array of objects; each obj one csv row)
 * @returns missingHeaders with headers which were found missing
 * @returns rowsWithMissingValues with information about the Row (and column!) with missing data
 */
const csvFileToArray = async (string) => {
    //Split up the CSV headers and the CSV rows by slicing the raw string
    const csvHeader = string.slice(0, string.indexOf("\n")).split(";");
    //Use the library to create the Array of Objects
    const options = {
        delimiter: {
            field: ';',
            eol: '\n'
        },
        prependHeader: true,
        keys: csvHeader
    };
    const parsedCSV = await (0, json_2_csv_1.csv2jsonAsync)(string, options);
    //Check if all headers of the config exist in the uploaded CSV, otherwise add to missingHeaders
    const missingHeaders = [];
    config_1.csvHeadersConfig?.forEach((header) => {
        let headerExist = csvHeader.includes(header?.name);
        if (!headerExist) {
            missingHeaders.push(header?.name);
        }
    });
    //Check for missing values in required headers
    const rowsWithMissingValues = [];
    parsedCSV?.forEach((line) => {
        csvHeader?.forEach((header, index) => {
            //If a value is missing, check is the header is marked as "required" in the csvHeaderConfig
            let isRequired = (0, config_1.lookupHeadersConfig)({ headerName: header }).required;
            if (line[header] === "" && isRequired) {
                rowsWithMissingValues.push({
                    row: line["Nummer"],
                    header: header,
                    stelle: index
                });
            }
        });
    });
    return { arrayOfAddressObjects: parsedCSV, missingHeaders, rowsWithMissingValues };
};
exports.csvFileToArray = csvFileToArray;
/**
 * Function which transforms the Array of objects to have a matched Object with the Strapi Content Type "Address"
 */
const transformParsedToAddresses = async (obj) => {
    const addresses = [];
    obj.forEach((line) => {
        addresses.push({
            [(0, config_1.lookupHeadersConfig)({ id: "nummer" }).id]: Number(line?.Nummer),
            [(0, config_1.lookupHeadersConfig)({ id: "vorname" }).id]: line.Vorname,
            [(0, config_1.lookupHeadersConfig)({ id: "nachname" }).id]: line.Nachname,
            [(0, config_1.lookupHeadersConfig)({ id: "title" }).id]: line["Titel (optional)"],
            [(0, config_1.lookupHeadersConfig)({ id: "companyName" }).id]: line["Firmenname (optional)"],
            [(0, config_1.lookupHeadersConfig)({ id: "land" }).id]: line?.Land || "Deutschland",
            [(0, config_1.lookupHeadersConfig)({ id: "plz" }).id]: line?.PLZ.toString(),
            [(0, config_1.lookupHeadersConfig)({ id: "city" }).id]: line?.Ort,
            [(0, config_1.lookupHeadersConfig)({ id: "strasse" }).id]: line?.Straße,
            [(0, config_1.lookupHeadersConfig)({ id: "hausnummer" }).id]: line?.Hausnummer.toString(),
            [(0, config_1.lookupHeadersConfig)({ id: "strasse2" }).id]: line["Straße 2 (optional)"],
            [(0, config_1.lookupHeadersConfig)({ id: "email" }).id]: line["E-Mail (optional)"],
            [(0, config_1.lookupHeadersConfig)({ id: "salutation" }).id]: line["Grußkarte: Individuelle Ansprache"],
            //Individueller Haupttext kommt nicht mehr aus dem CSV ! 
        });
    });
    return addresses;
};
exports.transformParsedToAddresses = transformParsedToAddresses;
/**
 * Function which transforms the Array of objects lineItems from CSV to lineItems Strapi Content Type
 * @uses parseProductsOfAddress
 */
const parseProductsToConfiguration = async (obj) => {
    const products = [];
    obj.forEach((line) => {
        const prodObj = parseProductsOfAddress(line);
        const productConfigString = JSON.stringify(prodObj);
        products.push({
            prodConfString: productConfigString,
            products: prodObj
        });
    });
    return products;
};
exports.parseProductsToConfiguration = parseProductsToConfiguration;
/**
 * Function which parses one Address Line of the CSV to a productObject
 */
const parseProductsOfAddress = (line) => {
    const prodObj = {
        [(0, config_1.lookupHeadersConfig)({ id: "produkt1" }).id]: line["Produkt 1 (individuell)"],
        [(0, config_1.lookupHeadersConfig)({ id: "quantityP1" }).id]: line["Quantity P1"],
        [(0, config_1.lookupHeadersConfig)({ id: "produkt2" }).id]: line["Produkt 2"],
        [(0, config_1.lookupHeadersConfig)({ id: "quantityP2" }).id]: line["Quantity P2"],
        [(0, config_1.lookupHeadersConfig)({ id: "produkt3" }).id]: line["Produkt 3"],
        [(0, config_1.lookupHeadersConfig)({ id: "quantityP3" }).id]: line["Quantity P3"],
    };
    return prodObj;
};
exports.parseProductsOfAddress = parseProductsOfAddress;
