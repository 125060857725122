"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const Box_1 = require("@strapi/design-system/Box");
const Divider_1 = require("@strapi/design-system/Divider");
const Typography_1 = require("@strapi/design-system/Typography");
const helper_plugin_1 = require("@strapi/helper-plugin");
const Flex_1 = require("@strapi/design-system/Flex");
const getNumberOfAddresses = (addressBlocks) => addressBlocks?.reduce((numberOfAddresses, addressBlock) => {
    return numberOfAddresses + (addressBlock?.addressCount ?? 0);
}, 0);
const BulkOrderInfo = () => {
    const data = (0, helper_plugin_1.useCMEditViewDataManager)();
    const numberOfAddressBlocks = data?.modifiedData?.bulk_order_address_blocks?.length;
    const numberOfAddresses = getNumberOfAddresses(data?.modifiedData?.bulk_order_address_blocks);
    /*
    * Check for window.location
    */
    const [isBulkOrder, setIsBulkOrder] = (0, react_1.useState)(window.location.href.includes("api::bulk-order.bulk-order"));
    (0, react_1.useEffect)(() => {
        const handler = () => setIsBulkOrder(window.location.href.includes("api::bulk-order.bulk-order"));
        window.addEventListener("onhashchange", handler);
        return () => window.removeEventListener("onhashchange", handler);
    }, []);
    if (!isBulkOrder || !data?.initialData?.id) {
        return null;
    }
    return (react_1.default.createElement(react_1.default.Fragment, null, isBulkOrder && (react_1.default.createElement(Box_1.Box, { paddingTop: 6 },
        react_1.default.createElement(Typography_1.Typography, { variant: "sigma", textColor: "neutral600", id: "csvUpload" }, "BulkOrder CSV Info"),
        react_1.default.createElement(Box_1.Box, { paddingTop: 2, paddingBottom: 2 },
            react_1.default.createElement(Divider_1.Divider, null)),
        react_1.default.createElement(Flex_1.Flex, { paddingTop: 2, justifyContent: "space-between" },
            react_1.default.createElement(Typography_1.Typography, { variant: "omega", fontWeight: "bold" }, "# of AddressBlocks"),
            react_1.default.createElement(Typography_1.Typography, { variant: "omega" }, numberOfAddressBlocks > 0 ? numberOfAddressBlocks : "-")),
        react_1.default.createElement(Flex_1.Flex, { paddingTop: 4, justifyContent: "space-between" },
            react_1.default.createElement(Typography_1.Typography, { variant: "omega", fontWeight: "bold" }, "# of Addresses"),
            react_1.default.createElement(Typography_1.Typography, { variant: "omega" }, numberOfAddresses > 0 ? numberOfAddresses : "-"))))));
};
exports.default = BulkOrderInfo;
