"use strict";
/**
 *
 * This file contains different configurations for the CSV file
 * @lookupHeadersConfig function which returns the exact match of a header -> lookup function
 * @csvHeadersConfig is the Array of Header Objects with all the needed data to use in the Plugin
 * @csvHeadersForDownload The exact same array but only containing the headerName -> we need this for the json2csvAsync functuon for the download
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.csvHeadersForDownload = exports.csvHeadersConfig = exports.lookupHeadersConfig = void 0;
const lookupHeadersConfig = ({ id, headerName }) => {
    const searchHeader = headerName?.trim() || undefined;
    if (id)
        return exports.csvHeadersConfig.find((x) => x.id === id);
    if (searchHeader)
        return exports.csvHeadersConfig.find((x) => x.name === searchHeader);
    throw new Error(`No result for ${id ?? headerName} in headers config!`);
};
exports.lookupHeadersConfig = lookupHeadersConfig;
exports.csvHeadersConfig = [
    { name: "Nummer", required: true, id: "nummer" },
    { name: "Vorname", required: true, id: "vorname" },
    { name: "Nachname", required: true, id: "nachname" },
    { name: "Titel (optional)", required: false, id: "title" },
    { name: "Firmenname (optional)", required: false, id: "companyName" },
    { name: "Land", required: true, id: "land" },
    { name: "PLZ", required: true, id: "plz" },
    { name: "Ort", required: true, id: "city" },
    { name: "Straße", required: true, id: "strasse" },
    { name: "Hausnummer", required: true, id: "hausnummer" },
    { name: "Straße 2 (optional)", required: false, id: "strasse2" },
    { name: "E-Mail (optional)", required: false, id: "email" },
    { name: "Grußkarte: Individuelle Ansprache", required: false, id: "salutation" },
    { name: "Grußkarte: Individueller Haupttext", required: false, id: "salutation2" },
    { name: "Produkt 1 (individuell)", required: false, id: "produkt1" },
    { name: "Quantity P1", required: false, id: "quantityP1" },
    { name: "Produkt 2", required: false, id: "produkt2" },
    { name: "Quantity P2", required: false, id: "quantityP2" },
    { name: "Produkt 3", required: false, id: "produkt3" },
    { name: "Quantity P3", required: false, id: "quantityP3" },
    { name: "Paket: Trackinglink", required: false, id: "trackingLink" },
    { name: "Paket: Trackingnummer", required: false, id: "trackingNumber" },
];
exports.csvHeadersForDownload = [
    "Nummer",
    "Vorname",
    "Nachname",
    "Titel (optional)",
    "Firmenname (optional)",
    "Land",
    "PLZ",
    "Ort",
    "Straße",
    "Hausnummer",
    "Straße 2 (optional)",
    "E-Mail (optional)",
    "Grußkarte: Individuelle Ansprache",
    "Grußkarte: Individueller Haupttext",
    "Produkt 1 (individuell)",
    "Quantity P1",
    "Produkt 2",
    "Quantity P2",
    "Produkt 3",
    "Quantity P3",
    "Paket: Trackinglink",
    "Paket: Trackingnummer",
];
